import * as Yup from 'yup'

const ContactFormSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  age: Yup.number()
    .typeError('you must specify a number')
    .min(18, 'Min value 18.')
    .max(99, 'Max value 99.'),
  contactNumber: Yup.string()
    .required('This field is Required')
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      'Phone number is not valid',
    ),
  location: Yup.string().required('Required'),
  natureOfConcern: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  accept: Yup.bool().oneOf(
    [true],
    'Please accept the terms and conditions in order to proceed.',
  ),
  accept2: Yup.bool().oneOf(
    [true],
    'Please accept the terms and conditions in order to proceed.',
  ),
})

export default ContactFormSchema
