import React from 'react'
import { Link } from 'gatsby'
import { getRoute } from '../../../helpers'
import { withTrans } from '../../../i18n/withTrans'
import ArrowIconRight from '../../../assets/icons/pacificcross_icons_arrow-right.svg'
import './form-info-styles.scss'

const FormInfo = ({
  title,
  tag,
  description,
  btnLink,
  btnText,
  i18n,
}) => (
  <div className="col-12 col-md-6 formInfo__container">
    <div className="formInfo__content">
      <p className="tag">{tag}</p>
      <h1 className="page-title">{title}</h1>
      <div
        className="rich-text"
        dangerouslySetInnerHTML={{
          __html: description,
        }}
      />
      {btnText && (
        <Link to={getRoute({
          locale: i18n.language,
          route: btnLink,
        })}>
          <span className="formInfo__link">
            {btnText}
            <ArrowIconRight width="30px" />
          </span>
        </Link>
      )}
    </div>
  </div>
)

export default withTrans(FormInfo)
